<template>
  <li class="context-menu-item">
    <slot />
  </li>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
@use '~/src/assets/sass/_variables' as *
.context-menu-item
  display: block
  position: relative
  padding: 2px 2px
  background: $bg-dark
  border-radius: 0
  color: #afb7c9
  text-decoration: none
  font-size: 13px
  width: 100%
  text-align: left
  cursor: pointer
  padding: 8px

  &:hover,
  &:focus
    background: #3a4652
    //color:#000
    outline: none
</style>

