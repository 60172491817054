<template>
  <div
    v-show="isVisible"
    ref="popper"
    v-click-outside="close"
    class="context-menu"
    :tabindex="opened ? 0 : -1"
    @contextmenu.capture.prevent
    @keyup.esc="opened ? close() : null"
  >
    <ul>
      <slot :contextData="contextData" />
    </ul>
  </div>
</template>

<script>
import Popper from 'popper.js';
import ClickOutside from 'vue-click-outside';
// @vue/component
export default {
  components: {
    //Popper,
  },
  directives: {
    ClickOutside,
  },
  props: {
    boundariesElement: {
      type: String,
      default: 'body',
    },
  },
  data() {
    return {
      opened: false,
      contextData: {},
    };
  },
  computed: {
    isVisible() {
      return this.opened;
    },
  },
  beforeDestroy() {
    if (this.popper !== undefined) {
      this.popper.destroy();
    }
  },
  methods: {
    open(evt, contextData) {
      this.opened = true;
      this.contextData = contextData;

      if (this.popper) {
        this.popper.destroy();
      }

      this.popper = new Popper(this.referenceObject(evt), this.$refs.popper, {
        placement: 'right-start',
        modifiers: {
          preventOverflow: {
            boundariesElement: document.querySelector(this.boundariesElement),
          },
        },
      });

      // Recalculate position
      this.$nextTick(() => {
        this.popper.scheduleUpdate();
        this.$refs.popper.focus(); // focus to use Esc key to close()
      });
    },
    close() {
      this.opened = false;
      this.contextData = null;
    },
    referenceObject(evt) {
      const left = evt.clientX;
      const top = evt.clientY;
      const right = left + 1;
      const bottom = top + 1;
      const clientWidth = 1;
      const clientHeight = 1;

      function getBoundingClientRect() {
        return {
          left,
          top,
          right,
          bottom,
        };
      }

      const obj = {
        getBoundingClientRect,
        clientWidth,
        clientHeight,
      };
      return obj;
    },
  },
};
</script>

<style lang="sass" scoped>
@use '~/src/assets/sass/_variables' as *

.context-menu
  position: fixed
  z-index: 999
  overflow: hidden
  background: $bg-dark
  border-radius: 4px
  box-shadow: 0 1px 4px 0 rgb(16, 17, 20)
  max-height: 80vh
  overflow: auto

  &:focus
    outline: none

  ul
    padding: 0px
    margin: 0px
</style>
